<template>
  <chat
    :channel="channel"
    :token="token"
    :user="user"
    :mobile="isMobile"
    class="chat"
  />
</template>

<script>
import Chat from '../../components/chat/chat.vue'

export default {
  components: {
    Chat,
  },
  data() {
    return {
      channel: '',
      user: '',
      token: '',
      isMobile: true,
      channelData: {},
    }
  },
  created() {
  },
  mounted() {
    this.channel = window.location.pathname.substring(1).replace('/mobile-chat', '')
    const urlParams = new URLSearchParams(window.location.search)
    this.user = urlParams.get('user')
    this.token = urlParams.get('token')
  },
}
</script>

<style>
.chat{
    height: calc(100vh);
    min-height: calc(100vh);
}
</style>
